import Section from "./Section"
import sprite from './images/sprites/contact.png';
import speechBubble from './images/sprites/contacttext.png';

const Contact = () => {
  return (
    <Section>
      <div className="lefttext">
        <div className="maintext">Drop me a line!</div>
        <div>Questions? Comments? Concerns? Contact kelsey at xpstudios dot co!</div>
      </div>
      <div className="rightspritecontainer">
        <div className="rightsprite">
          <img src={speechBubble} width={192} className="rightspritetext" />
          <img src={sprite} width={768} className="rightspriteimage"/>
        </div>
      </div>
    </Section>
  );
}

export default Contact;
