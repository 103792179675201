import sprite from './images/sprites/mailinglist.png';
import speechBubble from './images/sprites/mailinglisttext.png';

import Section from './Section';

const iframeSrc = "https://f3105f4d.sibforms.com/serve/MUIEAPI9Og-WFZ8bDz8wm0PvxGcFgcf1cFOOBTHJ2S5ilKwwzl2TetYefd2ftmMCwDdb7i-UJ4q9YbTe4wK_tRJNHqs2pDOqwiW2MYG6CvB4s5ncwgPlHqyVMIGSX_kCeH2eq0jUQneV09tDMzp-WUzQm7Y7jSrZG6xqNGaa_sVrmKSX5wWKWULwng08tj1RjOuV8IoBWhONrUct";

const MailingList = () => {
  return (
    <Section>
      <div>
        <div className="maintext">
          Join the mailing list to get updates about dev, art, and more!
        </div>
        <div className="sprite spritelarge">
        <img src={speechBubble} width={192} className="spritetext" />
          <img src={sprite} width={192} className="spriteimage"/>
        </div>
      </div>
      <div>
        <iframe
          className="mailinglistiframe"
          src={iframeSrc}
          frameBorder="0"
          scrolling="auto"
          allowFullScreen
        />
      </div>


    </Section>
  )
}

export default MailingList;
