import Section from "./Section";
import sprite from './images/sprites/demo.png';
import speechBubble from './images/sprites/demotext.png';

const Demo = () => {
  return (
    <Section>
      <div className="maintext">Demo coming soon to browsers near you!</div>
      <div className="sprite">
        <img src={speechBubble} width={192} className="spritetext" />
        <img src={sprite} width={192} className="spriteimage"/>
      </div>
    </Section>
  );
}

export default Demo;
