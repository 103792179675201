import "./Section.css";

const Section = ({
  children
}) => {
  return (
    <div className="section">
      <div className="content">
        <div className="contentcontainer">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Section;