import "./Title.css";

const Title = () => {
  return (
    <div className="section">
      <div className="content">
        <div className="titlecontainer">
          <div className="title centeredtext">Welcome to XP Studios!</div>
          <div className="motto centeredtext">Making dreams come true since twenty twenty-two</div>
          <div className="smalltext centeredtext">(Website under construction)</div>
        </div>
        <div className="maintext">XP Studios is a one-man game studio working on Birdfeeder App, a free collectathon about inviting birds to your backyard.</div>
      </div>
    </div>
  );
};

export default Title;