import Section from "./Section";
import patreonButton from './images/patreonlogo.png';
import patreonSprite from './images/sprites/patreon.png';
import patreonText from './images/sprites/patreontext.png';
import paypalSprite from './images/sprites/paypal.png';
import paypalText from './images/sprites/paypaltext.png';

import './Membership.css';

const Membership = () => {
  return (
    <>
      <Section>
        <div>
          <div className="maintext textblock">
            Become a member with Patreon!
          </div>
          <div className="patreoncontainer">
          <a
            href="https://www.patreon.com/bePatron?u=55375719"
            target="_blank"
            className="patreonbutton">
            <div className="patreonbuttoncontent">
              <img
                width={16}
                src={patreonButton}
              />
              <div className="spacer"></div>
              Become a patron
            </div>
          </a>
          
        </div>
        </div>
        <div className="sprite">
          <img src={patreonText} width={192} className="spritetext" />
          <img src={patreonSprite} width={192} className="spriteimage"/>
        </div>

      </Section>
      <Section>
        <div>
          <div className="textblock">
            <div className="maintext">Or...Paypal!</div>
            <div className="smalltext">You can have all the same rewards as Patreon!</div>
          </div>
          <form action="https://www.paypal.com/donate" method="post" target="_blank">
            <input type="hidden" name="business" value="3L5UNLNRRM2U4" />
            <input type="hidden" name="no_recurring" value="0" />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </form>
        </div>
        <div className="sprite">
          <img src={paypalText} width={192} className="spritetext" />
          <img src={paypalSprite} width={192} className="spriteimage"/>
        </div>
      </Section>
  </>  
  );
}

export default Membership;
