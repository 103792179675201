import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

import testGif from './images/gifs/testgif.gif';
import CoverPhoto from './CoverPhoto';
import Title from './Title';
import Demo from './Demo';
import MailingList from './MailingList';
import Membership from './Membership';
import Contact from './Contact';
import Footer from './Footer';
import './App.css';

const firebaseConfig = {
  apiKey: "AIzaSyA3wtXUVwwvhE7R8a3Kbc4_GtRq3YLl-og",
  authDomain: "xp-studios-website.firebaseapp.com",
  projectId: "xp-studios-website",
  storageBucket: "xp-studios-website.appspot.com",
  messagingSenderId: "578721526188",
  appId: "1:578721526188:web:53fec33d2b22da9d9c4a09",
  measurementId: "G-KB3Z907L68"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="app">
      <CoverPhoto />
      <Title />
      <Demo />
      <MailingList />
      <Membership />
      <Contact />
      <Footer />
      </div>
  );
}

export default App;
