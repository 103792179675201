import './CoverPhoto.css';

const CoverPhotoSprite = ({
  imageSrc,
}) => {
  return (
    <div className="spritecontainer">
      <img src={imageSrc} className="spriteimage"/>
    </div>
  );
}

export default CoverPhotoSprite;
