import Section from "./Section"

const Footer = () => {
  return (
    <Section>

    </Section>
  )
}

export default Footer;
