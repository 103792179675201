import { useEffect, useRef, useState } from 'react';
import CoverPhotoSprite from './CoverPhotoSprite';

import acf from './images/coverphoto/acf.png';
import acfAlt from './images/coverphoto/acf_alt.png';
import acm from './images/coverphoto/acm.png';
import acmAlt from './images/coverphoto/acm_alt.png';
import agf from './images/coverphoto/agf.png';
import agfAlt from './images/coverphoto/agf_alt.png';
import agm from './images/coverphoto/agm.png';
import agmAlt from './images/coverphoto/agm_alt.png';
import arf from './images/coverphoto/arf.png';
import arfAlt from './images/coverphoto/arf_alt.png';
import arm from './images/coverphoto/arm.png';
import armAlt from './images/coverphoto/arm_alt.png';
import bccf from './images/coverphoto/bccf.png';
import bccfAlt from './images/coverphoto/bccf_alt.png';
import bccm from './images/coverphoto/bccm.png';
import bccmAlt from './images/coverphoto/bccm_alt.png';
import bjf from './images/coverphoto/bjf.png';
import bjfAlt from './images/coverphoto/bjf_alt.png';
import bjm from './images/coverphoto/bjm.png';
import bjmAlt from './images/coverphoto/bjm_alt.png';
import bof from './images/coverphoto/bof.png';
import bofAlt from './images/coverphoto/bof_alt.png';
import bom from './images/coverphoto/bom.png';
import bomAlt from './images/coverphoto/bom_alt.png';
import bsf from './images/coverphoto/bsf.png';
import bsfAlt from './images/coverphoto/bsf_alt.png';
import bsm from './images/coverphoto/bsm.png';
import bsmAlt from './images/coverphoto/bsm_alt.png';
import cgf from './images/coverphoto/cgf.png';
import cgfAlt from './images/coverphoto/cgf_alt.png';
import cgm from './images/coverphoto/cgm.png';
import cgmAlt from './images/coverphoto/cgm_alt.png';
import csf from './images/coverphoto/csf.png';
import csfAlt from './images/coverphoto/csf_alt.png';
import csm from './images/coverphoto/csm.png';
import csmAlt from './images/coverphoto/csm_alt.png';
import dwf from './images/coverphoto/dwf.png';
import dwfAlt from './images/coverphoto/dwf_alt.png';
import dwm from './images/coverphoto/dwm.png';
import dwmAlt from './images/coverphoto/dwm_alt.png';
import ebf from './images/coverphoto/ebf.png';
import ebfAlt from './images/coverphoto/ebf_alt.png';
import ebm from './images/coverphoto/ebm.png';
import ebmAlt from './images/coverphoto/ebm_alt.png';
import hff from './images/coverphoto/hff.png';
import hffAlt from './images/coverphoto/hff_alt.png';
import hfm from './images/coverphoto/hfm.png';
import hfmAlt from './images/coverphoto/hfm_alt.png';
import hsf from './images/coverphoto/hsf.png';
import hsfAlt from './images/coverphoto/hsf_alt.png';
import hsm from './images/coverphoto/hsm.png';
import hsmAlt from './images/coverphoto/hsm_alt.png';
import mdf from './images/coverphoto/mdf.png';
import mdfAlt from './images/coverphoto/mdf_alt.png';
import mdm from './images/coverphoto/mdm.png';
import mdmAlt from './images/coverphoto/mdm_alt.png';
import ncf from './images/coverphoto/ncf.png';
import ncfAlt from './images/coverphoto/ncf_alt.png';
import ncm from './images/coverphoto/ncm.png';
import ncmAlt from './images/coverphoto/ncm_alt.png';
import rwbf from './images/coverphoto/rwbf.png';
import rwbfAlt from './images/coverphoto/rwbf_alt.png';
import rwbm from './images/coverphoto/rwbm.png';
import rwbmAlt from './images/coverphoto/rwbm_alt.png';
import tsf from './images/coverphoto/tsf.png';
import tsfAlt from './images/coverphoto/tsf_alt.png';
import tsm from './images/coverphoto/tsm.png';
import tsmAlt from './images/coverphoto/tsm_alt.png';

import './CoverPhoto.css';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const CoverPhoto = () => {
  const [isAlt, setIsAlt] = useState(false);
  const isAltRef = useRef(isAlt);
  isAltRef.current = isAlt;

  const [shouldTurnOffGIFs, setShouldTurnOffGIFs] = useState(false);

  useInterval(() => {
    // console.log("changeGIFs", isAltRef.current, new Date());
    if (!shouldTurnOffGIFs) {
      setIsAlt(!isAlt);
    }
  }, 2500);

  return (
    <div className="coverphotocontainer">
      <div className="coverphoto">
        <CoverPhotoSprite imageSrc={isAlt ? acmAlt : acm} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? agmAlt : agm} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? armAlt : arm} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccmAlt : bccm} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? bjmAlt : bjm} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? bomAlt : bom} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? cgmAlt : cgm} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? hsmAlt : hsm} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? ncmAlt : ncm} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? ebmAlt : ebm} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? hfmAlt : hfm} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? csmAlt : csm} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? dwmAlt : dwm} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? tsmAlt : tsm} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? bsmAlt : bsm} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? mdmAlt : mdm} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? rwbmAlt : rwbm} isOdd={true}/>

        <CoverPhotoSprite imageSrc={isAlt ? acfAlt : acf} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? agfAlt : agf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? arfAlt : arf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccfAlt : bccf} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? bjfAlt : bjf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? bofAlt : bof} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? cgfAlt : cgf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? hsfAlt : hsf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? ncfAlt : ncf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? ebfAlt : ebf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? hffAlt : hff} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? csfAlt : csf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? dwfAlt : dwf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? tsfAlt : tsf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? bsfAlt : bsf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? mdfAlt : mdf} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? rwbfAlt : rwbf} isOdd={true}/>

        <CoverPhotoSprite imageSrc={isAlt ? bccmAlt : bccm} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccfAlt : bccf} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccmAlt : bccm} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccfAlt : bccf} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccmAlt : bccm} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccfAlt : bccf} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccmAlt : bccm} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccfAlt : bccf} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccmAlt : bccm} isOdd={true}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccfAlt : bccf} isOdd={false}/>
        <CoverPhotoSprite imageSrc={isAlt ? bccmAlt : bccm} isOdd={true}/>
      </div>
      <div className="gifoptions">
        <input
          type="checkbox"
          value={shouldTurnOffGIFs}
          onClick={() => {
            setShouldTurnOffGIFs(!shouldTurnOffGIFs);
          }}
        />
        Turn off the dang GIFs
      </div>
    </div>
  );
};

export default CoverPhoto;
